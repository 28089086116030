import { useContext, useState } from "react";
import { Box, Stack, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UIContext, UIState } from "../../providers/UIProvider";
import { handleUnchangedNavigation } from "../../common/fx";
import { LoadingButton } from "@mui/lab";
import SaveTemplate from "../../modals/SaveTemplate";
import CreateStartMemo from "../../modals/CreateStartMemo";
import ApproveReject from "../../modals/ApproveRejectModal";
import useAccessControl from "../../hooks/useAccessControl";

const EditFooter = ({
  downloading,
  handleDownloadItems,
  retrieveIntentionData,
}: any) => {
  const navigate = useNavigate();
  const approvalAccess = useAccessControl("Intention", "UpdateApproval");
  const createStartMemoAccess = useAccessControl("Job", "CreateStartMemo");
  const updateStartMemoAccess = useAccessControl("Job", "UpdateStartMemo");
  const saveTemplateAccess = useAccessControl("OptionTemplate", "SaveTemplate");

  //const theme = useTheme();

  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");

  const [state, dispatch] = useContext<UIState | any>(UIContext);

  const hasAccess =
    (createStartMemoAccess && state.changeOrder.startMemoId === null) ||
    (updateStartMemoAccess && state.changeOrder.startMemoId !== null);

  return (
    <Stack
      className="edit-footer"
      justifyContent={"space-between"}
      sx={{
        width: "100%",
        flexDirection: "row",
        marginTop: "0!important",
        alignItems: "center",
      }}
    >
      <Box width={"100%"} display={"flex"} alignItems={"center"}>
        <Button
          onClick={() =>
            handleUnchangedNavigation(
              `/job-summary?addressid=${intentionId}`,
              state.changeOrder.status,
              navigate,
              dispatch
            )
          }
          variant="outlined"
        >
          Back
        </Button>
      </Box>

      <Box
        width={"100%"}
        gap={2}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        {saveTemplateAccess && <SaveTemplate disabled={false} />}{" "}
        <Stack>
          <LoadingButton
            fullWidth
            loading={downloading}
            variant="outlined"
            onClick={() => handleDownloadItems()}
          >
            Print
          </LoadingButton>
        </Stack>
        {state.changeOrder.startMemoEligible &&
          createStartMemoAccess &&
          hasAccess && (
            <CreateStartMemo
              buttonLabel={
                state.changeOrder.startMemoId === null
                  ? "Create Start Memo"
                  : "Update Start Memo"
              }
              startMemoId={
                state.changeOrder.startMemoId !== null
                  ? state.changeOrder.startMemoId
                  : undefined
              }
              title={
                state.changeOrder.startMemoId === null
                  ? `Create Start Memo  - ${state.changeOrder.address.address} | ${state.changeOrder.address.projectNumber}-${state.changeOrder.address.jobNumber} |  ${state.changeOrder.plan.planNumber}- ${state.changeOrder.plan.planElevationNumber}`
                  : `Update Start Memo - ${state.changeOrder.address.address} | ${state.changeOrder.address.projectNumber}-${state.changeOrder.address.jobNumber} | ${state.changeOrder.plan.planNumber}-${state.changeOrder.plan.planElevationNumber}`
              }
            />
          )}
        {approvalAccess && (
          <ApproveReject
            retrieveIntentionData={retrieveIntentionData}
            approvalHistory={state.changeOrder.approvalHistory}
            currentApprovalStatus={state.changeOrder.currentApprovalStatus}
          />
        )}
      </Box>
    </Stack>
  );
};

export default EditFooter;
