import { useContext, useState, useEffect } from "react";
import { UIContext, UIState } from "../../providers/UIProvider";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddComment,
  Check,
  Close,
  History,
  Message,
  Note,
  RateReview,
  Remove,
  ThumbDownAltOutlined,
  ThumbsUpDownOutlined,
  ThumbUpAltSharp,
  ThumbUpOutlined,
  ThumbUpSharp,
  Timeline,
} from "@mui/icons-material";
import { Stack } from "@mui/system";
import moment from "moment";
import { updateApprovalCO } from "../../apiCalls";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { msalInstance } from "../../index";

type Props = {
  approvalHistory: {
    action: string;
    addedBy: string;
    changedBy: string;
    effective: string;
    notes: string;
  }[];
  currentApprovalStatus?:
    | "PendingReview"
    | "Approved"
    | "FurtherActionNeeded"
    | null;
  retrieveIntentionData: () => void;
};

const ApproveReject = ({
  approvalHistory,
  currentApprovalStatus,
  retrieveIntentionData,
}: Props) => {
  const [searchParams] = useSearchParams();

  let intentionId = searchParams.get("intentionId");
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [historyNotes, setHistoryNotes] =
    useState<Props["approvalHistory"]>(approvalHistory);

  const [tempNote, setTempNote] = useState<string>("");

  const handleConfirm = (
    status: "PendingReview" | "Approved" | "FurtherActionNeeded"
  ) => {
    updateApprovalCO(
      {
        intentionId: intentionId as string,
        newState: status,
        notes: tempNote,
      },
      (res: any) => {
        console.log("success??");
        const payload = {
          ...state.changeOrder,
          currentApprovalStatus: status,
          approvalHistory: [
            {
              action: status,
              notes: tempNote,
              effectiveUntil: null,
              effective: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSSZ"),
              addedBy: msalInstance.getActiveAccount()?.name,
              changedBy: msalInstance.getActiveAccount()?.name,
            },
            ...(state.changeOrder.approvalHistory || []),
          ],
        };
        retrieveIntentionData();

        dispatch({
          type: "ChangeOrder",
          payload,
          source: "edit approval state/update-history",
        });
        setTempNote("");
      },
      (err: any) => console.log(err)
    );
    console.log("set temp notes");
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setHistoryNotes(approvalHistory);
  }, [approvalHistory]);

  return (
    <ReusableDialog
      setIsOpen={setOpen}
      isOpen={open}
      maxWidth={"lg"}
      buttonText={"Review Submitted CO"}
      title={`Submitted CO Ops Status: ${
        historyNotes && historyNotes[0]?.action
          ? ` ${historyNotes[0].action}`
          : "Pending Review"
      }`}
      buttonVariant="contained"
      content={
        <>
          <DialogContent>
            <Box padding={(theme) => theme.spacing(1)}>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  color: "primary.main",
                  fontWeight: 600,
                }}
              >
                <Message /> Review Comment
              </Typography>
              <TextField
                multiline
                fullWidth
                onChange={(e: any) => setTempNote(e.target.value)}
                rows={6}
                sx={{
                  mt: 1,
                  mb: 0.5,
                }}
                defaultValue={tempNote}
              />
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  color: "primary.main",
                  fontWeight: 600,
                }}
              >
                <History /> Approval History
              </Typography>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {historyNotes &&
                  historyNotes.map((note, index) => (
                    <>
                      <ListItem
                        disableGutters
                        key={note.effective}
                        alignItems="flex-start"
                        sx={{
                          bgcolor: index === 0 ? "action.hover" : "transparent",
                          borderRadius: 1,
                          paddingY: 0,
                        }}
                      >
                        <ListItemText
                          sx={{
                            paddingY: 0,
                          }}
                          primary={
                            <Stack
                              direction="row"
                              alignItems="flex-end"
                              gap={1}
                            >
                              {note.action &&
                                (note.action === "Approved" ? (
                                  <Check
                                    color={index === 0 ? "success" : "inherit"}
                                  />
                                ) : (
                                  <Close
                                    color={index === 0 ? "error" : "inherit"}
                                  />
                                ))}
                              <Typography
                                sx={{
                                  display: "inline",
                                  fontWeight: index === 0 ? 700 : 400,
                                }}
                                component="p"
                                variant="body1"
                                color="text.primary"
                              >
                                {note.action} by {note.addedBy} on{" "}
                                {moment(note.effective).format(
                                  "MM-DD-YYYY hh:mm A"
                                )}
                              </Typography>
                            </Stack>
                          }
                          secondary={
                            <Typography
                              sx={{
                                display: "inline",
                                fontWeight: index === 0 ? 600 : 400,
                              }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {note.notes}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider variant="fullWidth" component="li" />
                    </>
                  ))}
              </List>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={(e) => {
                setTempNote("");
                setOpen(false);
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Stack
              flexDirection={"row"}
              gap={2}
              justifyContent={"space-between"}
            >
              {currentApprovalStatus !== "PendingReview" && (
                <Button
                  disabled={isEmpty(tempNote)}
                  onClick={() => {
                    handleConfirm("PendingReview");
                    setOpen(false);
                  }}
                  variant="contained"
                  color="info"
                >
                  PendingReview
                </Button>
              )}
              {currentApprovalStatus !== "Approved" && (
                <Button
                  onClick={() => {
                    handleConfirm("Approved");
                    setOpen(false);
                  }}
                  variant="contained"
                  color="success"
                >
                  Approve
                </Button>
              )}

              {currentApprovalStatus !== "FurtherActionNeeded" && (
                <Button
                  disabled={isEmpty(tempNote)}
                  onClick={() => {
                    handleConfirm("FurtherActionNeeded");
                    setOpen(false);
                  }}
                  variant="contained"
                  color="error"
                >
                  Further Action
                </Button>
              )}
            </Stack>
          </DialogActions>
        </>
      }
    />
  );
};

export default ApproveReject;
